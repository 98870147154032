import { CountryListUtil, RoundingNear } from '../models/common'

export const COUNTRY_ID_INDONESIA = 'ID'
export const COUNTRY_ID_SINGAPORE = 'SG'
export const COUNTRY_ID_CAMBODIA = 'KH'
export const COUNTRY_ID_PHILIPPINES = 'PH'
export const COUNTRY_ID_USA = 'US'
export const COUNTRY_CHINESE = 'CN'


export const CountryList: CountryListUtil[] = [
  {
    name: COUNTRY_ID_INDONESIA,
    countryId: 'ID',
    currencyId: 'IDR',
    langId: 'id_ID',
    currencyLabel: 'IDR (Rp)',
    currencySymbol: 'IDR',
    locale: 'id-ID'
  },
  {
    name: COUNTRY_ID_SINGAPORE,
    countryId: 'SG',
    currencyId: 'SGD',
    langId: 'en_US',
    currencyLabel: 'SGD ($)',
    currencySymbol: 'SGD',
    locale: 'en-SG'
  },
  {
    name: COUNTRY_ID_CAMBODIA,
    countryId: 'KH',
    currencyId: 'KHR',
    langId: 'en_US',
    currencyLabel: 'KHR (៛)',
    currencySymbol: 'KHR',
    locale: 'km-KH'
  },
  {
    name: COUNTRY_CHINESE,
    countryId: 'CN',
    currencyId: 'CNY',
    langId: 'zh_HANS',
    currencyLabel: 'CNY (¥)',
    currencySymbol: 'CNY',
    locale: 'zh-HANS',
  },
  {
    name: COUNTRY_ID_PHILIPPINES,
    countryId: 'PH',
    currencyId: 'PHP',
    langId: 'en_US',
    currencyLabel: 'PHP (₱)',
    currencySymbol: '₱',
    locale: 'en-PH',
  },
  {
    name: COUNTRY_ID_USA,
    countryId: 'US',
    currencyId: 'USD',
    langId: 'en_US',
    currencyLabel: 'USD ($)',
    currencySymbol: '$',
    locale: 'en-US',
  },
]

export enum ResponseStatus {
  // The request was successfully processed
  SUCCESS = 200,

  // The request has been accepted for processing, but the processing is not complete
  PENDING = 102,

  // The request lacks valid authentication credentials
  UNAUTHORIZED = 401,

  // Access to the requested resource is forbidden
  FORBIDDEN = 403,

  // The requested resource could not be found
  NOT_FOUND = 404,

  // An error occurred on the server while processing the request
  ERROR = 500,

  // The request is invalid (e.g., bad request)
  BAD_REQUEST = 400,

  // The request was successfully processed but no content is returned
  NO_CONTENT = 204,

  // The request was successful and a new resource was created
  CREATED = 201,

  // The request has been accepted for processing
  ACCEPTED = 202,

  // A conflict occurred with the request (e.g., duplicate data)
  CONFLICT = 409,

  // The resource requested has been removed
  GONE = 410,

  // The request method is not allowed for the requested resource
  METHOD_NOT_ALLOWED = 405,

  // The server requires proxy authentication to process the request
  PROXY_AUTHENTICATION_REQUIRED = 407,

  // The server is currently unable to handle the request due to overload
  SERVICE_UNAVAILABLE = 503,
}


export enum OrderStatus {
  CREATED = 1,
  UNPAID = 2,
  WAITING_SALES_CONFIRM = 3,
  ON_PROCESS = 4,
  WAITING_COURIER = 5,
  ON_SHIPPING = 6,
  ARRIVED = 7,
  READY_TO_PICKUP = 8,
  PICKED_UP = 9,
  REFUND = 10,
  VOID = 11,
  MERGED = 12,
  PAID = 40,
  COMPLETE = 90,
  CANCELED_EXPIRED_PAYMENT = 98,
  CANCELED = 99,
  PENDING = 50,
  READY_TO_SHIP = 51,
  SHIPPED = 52,
  DELIVERED = 53,
  PACKED = 54,
  SHIPPED_BACK_SUCCESS = 55,
  READY_TO_PICK_UP_DRIVER = 41,
  ASSIGNED_DRIVER = 44,
  FAILED_TO_FIND_DRIVER = 97,
  ACCEPTED = 45
}

export const STATUS_ORDER_LIST = [
  { status: OrderStatus.CREATED, info: 'CREATED' },
  { status: OrderStatus.UNPAID, info: 'UNPAID' },
  { status: OrderStatus.WAITING_SALES_CONFIRM, info: 'WAITING_SALES_CONFIRM' },
  { status: OrderStatus.ON_PROCESS, info: 'ON_PROCESS' },
  { status: OrderStatus.WAITING_COURIER, info: 'WAITING_COURIER' },
  { status: OrderStatus.ON_SHIPPING, info: 'ON_SHIPPING' },
  { status: OrderStatus.ARRIVED, info: 'ARRIVED' },
  { status: OrderStatus.READY_TO_PICKUP, info: 'READY_TO_PICKUP' },
  { status: OrderStatus.PICKED_UP, info: 'PICKED_UP' },
  { status: OrderStatus.REFUND, info: 'REFUND' },
  { status: OrderStatus.VOID, info: 'VOID' },
  { status: OrderStatus.MERGED, info: 'MERGED' },
  { status: OrderStatus.PAID, info: 'PAID' },
  { status: OrderStatus.COMPLETE, info: 'COMPLETE' },
  { status: OrderStatus.CANCELED_EXPIRED_PAYMENT, info: 'CANCELED_EXPIRED_PAYMENT' },
  { status: OrderStatus.CANCELED, info: 'CANCELED' },
  { status: OrderStatus.PENDING, info: 'PENDING' },
  { status: OrderStatus.READY_TO_SHIP, info: 'READY_TO_SHIP' },
  { status: OrderStatus.SHIPPED, info: 'SHIPPED' },
  { status: OrderStatus.DELIVERED, info: 'DELIVERED' },
  { status: OrderStatus.PACKED, info: 'PACKED' },
  { status: OrderStatus.SHIPPED_BACK_SUCCESS, info: 'SHIPPED_BACK_SUCCESS' },
  { status: OrderStatus.READY_TO_PICK_UP_DRIVER, info: 'DRIVER_ON_GOING' },
  { status: OrderStatus.FAILED_TO_FIND_DRIVER , info: "ASSIGNED_DRIVER"},
  { status: OrderStatus.ASSIGNED_DRIVER, info: "ASSIGNED_DRIVER"},
  { status: OrderStatus.ACCEPTED, info: "ACCEPTED"}
];

export enum OrderStatusText {
  CREATED = 'CREATED',
  UNPAID = 'UNPAID',
  WAITING_SALES_CONFIRM = 'WAITING_SALES_CONFIRM',
  ON_PROCESS = 'ON_PROCESS',
  WAITING_COURIER = 'WAITING_COURIER',
  ON_SHIPPING = 'ON_SHIPPING',
  ARRIVED = 'ARRIVED',
  READY_TO_PICKUP = 'READY_TO_PICKUP',
  PICKED_UP = 'PICKED_UP',
  REFUND = 'REFUND',
  VOID = 'VOID',
  MERGED = 'MERGED',
  PAID = 'PAID',
  COMPLETE = 'COMPLETE',
  CANCELED_EXPIRED_PAYMENT = 'CANCELED_EXPIRED_PAYMENT',
  CANCELED = 'CANCELED',
  PENDING = 'PENDING',
  READY_TO_SHIP = 'READY_TO_SHIP',
  SHIPPED = 'SHIPPED',
  DELIVERED = 'DELIVERED',
  PACKED = 'PACKED',
  SHIPPED_BACK_SUCCESS = 'SHIPPED_BACK_SUCCESS',
  READY_TO_PICK_UP_DRIVER = 'DRIVER_ON_GOING',
  FAILED_TO_FIND_DRIVR = 'FAILED_TO_FIND_DRIVER',
  ASSIGNED_DRIVER = 'ASSIGNED_DRIVER'
}

export const RoundingNearestList: RoundingNear[] = [
  { code: '0.1', name: '.00', divider: 0.1 },
  { code: '1.0', name: '.0', divider: 1 },
  { code: '10.0', name: '0', divider: 10 },
  { code: '100.0', name: '00', divider: 100 },
  { code: '1000.0', name: '000', divider: 1000 }
]

export const ROUNDING_MATH: string = 'MATH'
export const ROUNDING_UP: string = 'ROUNDING_UP'
export const ROUNDING_DOWN: string = 'ROUNDING_DOWN'

export const WEEKDAY = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday'
]

export const MicroSentryData = {
  AddToCart: {
    message: 'Add to Cart',
    tag: 'add_to_cart'
  },
  UpdateToCart: {
    message: 'Update to Cart',
    tag: 'update_to_cart'
  },
  DeleteCart: {
    message: 'Delete Cart',
    tag: 'delete_cart'
  },
  OrderNow: {
    message: 'Order Now',
    tag: 'order_now'
  },
  OrderDetail: {
    message: 'Order Detail',
    tag: 'order_detail'
  },
  PaymentForm: {
    message: 'Payment Form',
    tag: 'payment_form'
  },
  LogWSMerged: {
    message: 'Log Merged',
    tag: 'log_merged'
  },
  LogWSUpdateFromIDEPOS: {
    message: 'Log Update From IDEPOS',
    tag: 'log_update_from_idepos'
  },
  StoreInfo: {
    message: 'Log Store and qr Info',
    tag: 'log_store_and_qr_info'
  },
  PaymentMethodList: {
    message: 'Log Payment Methode',
    tag: 'log_payment_methode'
  }
}

export const PASSWORD_PATTERN = new RegExp('^(?=[^A-Z]*[A-Z])(?=\\D*\\d)[A-Za-z\\d-!$%@#£€_+=.^*?&]{8,}$')

export const ErrorMessage = {
  ORDER_HAS_BEEN_ALREADY_VOIDED: 'ORDER_HAS_BEEN_ALREADY_VOIDED',
  ORDER_HAS_BEEN_PAID: 'ORDER_HAS_BEEN_PAID',
  MERCHANT_TABLE_QR_SESSION_DEVICE_ID_NOT_VALID: 'MERCHANT_TABLE_QR_SESSION_DEVICE_ID_NOT_VALID',
  ORDER_CART_EMPTY: 'ORDER_CART_EMPTY',
  MERCHANT_TABLE_MANAGEMENT_NEED_ACTIVATE: 'MERCHANT_TABLE_MANAGEMENT_NEED_ACTIVATE',
  MERCHANT_NOT_FOUND: 'MERCHANT_NOT_FOUND',
  MERCHANT_TABLE_QR_OPEN_BILL_PAID: 'MERCHANT_TABLE_QR_OPEN_BILL_PAID',
  CUSTOMER_LOGIN_REQUIRED: 'CUSTOMER_LOGIN_REQUIRED',
  STOCK_HAS_RUN_OUT: 'STOCK_HAS_RUN_OUT'
} as const;

export const ConfigSetting = {
  STATIC: 'STATIC',
  DYNAMIC: 'DYNAMIC',
  FROM_CLAIM_POINTS: 'from-claim-points'
}

export enum ServiceType {
  DINE_IN = 'DINE_IN',
  TAKEOUT = 'TAKEOUT',
  DELIVERY = 'DELIVERY',
  CATALOG = 'CATALOG'
}

export const LalamoveService = {
  MOTORCYCLE: 'MOTORCYCLE',
  CAR: 'CAR',
}

export const SessionStorageKey = {
  attemptLongPolling: 'attemptLongPolling'
}

export const MAX_STOCK_PRODUCT = 9999
export const LoadingSpinnerList = {
  Default: {
    url: 'assets/images/illustration/il-burger-animation.gif',
    message: 'Loading',
    width: '380px',
    name: 'Burger Spinner',
    overlay: 'rgba(0, 0, 0, 0.8)'
  },
  Ichiho: {
    url: 'assets/images/illustration/il-ichiho-spinner.gif',
    message: 'Loading',
    width: '260px',
    name: 'Ichiho Spinner',
    overlay: 'rgb(65 65 65 / 46%)'
  }
}

export interface ILoadingSpinnerList {
  url: string,
  width: string
  message: string,
  name: string,
  overlay: string
}

export const localStorageKey = {
  isCartUpdated: 'isCartUpdated',
  storeInfo: 'storeInfo',
  qrInfo: 'qrInfo'
}

export const Percent = 100;
