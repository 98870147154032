import { Modifier, Option } from "./product.model"
import { ProductSpecialPrice } from "./utility.model"

/**
 * ProductV2 model
 */
export interface ProductV2 {
  id: number
  /**
   * The ID of the product under the menu
   */
  cmProductId: number// TODO: review where it is used
  categoryId: number
  status: number
  sequence: number
  price: number
  memberPrice: number
  stockTracked: boolean
  stock: number
  lowStock: number// TODO: review where it is used
  thumbnailUrl: string// TODO: review where it is used
  thumbnailId: number// TODO: review where it is used
  thumbnailColor: string // TODO: review where it is used
  description: string
  title: string
  cost: number
  recommended: boolean
  itemCode: string
  modifiers: ModifierV2[]
  sku: string
  taxIncluded: boolean
  isSpecialRequestNotesActive: boolean
  fixedPrice: boolean
  favorite?: boolean // TODO: check, review where it is used
  productSpecialPrice: ProductSpecialPrice[]
  promotionId: number
  promotionDiscountCut: number
  hasPromotionDiscountCut: boolean
  nutriGradeEnabled: boolean
  nutriGradeLevel: string
  nutriGradePercentage: number

  // TODO: Check with Alex
  isRecommended: boolean
}

export interface ModifierV2 {
  id: number
  mandatory: boolean
  multiple: boolean
  sequence: number
  name: string
  options: OptionV2[]
  qtyMax: number
  qtyMin: number
  statusModifier: number

  thumbnailUrl: string // TODO: Make sure this field will pass from REST API with Alex
  thumbnailId: number // TODO: Make sure this field will pass from REST API with Alex
}

export interface OptionV2 {
  id: number
  name: string
  status: number
  sequence: number
  maxAllowed: number
  price: number

  memberPrice: number
  description: string

  thumbnailUrl: string // TODO: Make sure this field will pass from REST API with Alex
  thumbnailId: number // TODO: Make sure this field will pass from REST API with Alex
}

export function adaptModifier(modifier: ModifierV2): Modifier {
  return {
    id: modifier.id,
    modifierName: modifier.name,
    name: modifier.name,
    status: modifier.statusModifier,
    multiple: modifier.multiple ? 1 : 0,
    mandatory: modifier.mandatory ? 1 : 0,
    minQty: modifier.qtyMin,
    maxQty: modifier.qtyMax,
    qtyCounter: 0,
    thumbnailUrl: modifier.thumbnailUrl,
    thumbnailId: modifier.thumbnailId,
    options: modifier.options.map(adaptOption)
  }
}

export function adaptOption(option: OptionV2): Option {
  return {
    id: option.id,
    name: option.name,
    quantity: 0,
    maxAllowed: option.maxAllowed,
    thumbnailId: option.thumbnailId,
    thumbnailUrl: option.thumbnailUrl
  }
}

