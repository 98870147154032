import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import {
  AfterViewInit,
  Component,
  DoCheck,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  Renderer2
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router';
import { DomainService } from '@data-access/domain/domain.service';
import { EventService } from '@data-access/event/event.service';
import { TimeZone } from '@models/utility.model';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { countOfCartUpdate } from '@state-management/count-of-cart/count-of-cart.actions';
import { selectStoreInfo } from '@state-management/store/store-info.selector';
import { ConfigSetting, ILoadingSpinnerList, LoadingSpinnerList, localStorageKey, MicroSentryData, ServiceType } from '@utils/const';
import { addScript, getCurrencyLocale } from '@utils/validator';
import { NgxSpinnerService } from 'ngx-spinner';
import { filter, Observable, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { v4 as uuidv4 } from 'uuid';
import { ViewService } from './shared/data-access/view/view.service';
import { DataStorageService } from './shared/data-storage/data-storage.service';
import { LogService } from './shared/log/log.service';
import { QRInfo } from './shared/models/domain.model';
import { StoreInfo } from './shared/models/home.model';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy, DoCheck {
  title = 'ideshop-food'
  storeSlug: string = ''
  subs: Subscription = new Subscription()
  storeInfo!: StoreInfo
  isShowStoreHoliday: boolean = false
  isShowStoreClosed: boolean = false
  qrInfo: QRInfo = new Object() as QRInfo
  isShowLanguage: boolean = false
  timezoneList: TimeZone[] = []
  currentTimezone?: TimeZone
  isShowModalContactCashier: boolean = false
  isShowModalMerged: boolean = false

  private hasRedirected = false;

  mergedEvent$Sub: Subscription = new Subscription()

  private routerSubscription?: Subscription;
  storeInfo$: Observable<StoreInfo> = this._store.select(selectStoreInfo)
  isDelivery: boolean = false
  deliveryNotAvailable: boolean = false
  isMerchantCustomLoadingSpinner: boolean =  false
  timeStart: string = ''
  timeEnd: string = ''
  availableDeliveryDays: string[] = []

  constructor(
    private readonly router: Router,
    private readonly renderer: Renderer2,
    @Inject(DOCUMENT) private readonly document: Document,
    @Inject(PLATFORM_ID) private readonly platformId: string,
    private readonly _viewService: ViewService,
    private readonly _activatedRoute: ActivatedRoute,
    translate: TranslateService,
    private readonly _eventService: EventService,
    private readonly _spinner: NgxSpinnerService,
    private _store: Store,
    private logService: LogService,
    private _dataStorageService: DataStorageService,
    private _domainService: DomainService
  ) {  

    if (!this._viewService.get('langCode')) {
      this._viewService.set('langCode', 'en-US')
    } else {
      const langCode = this._viewService.get('langCode') ?? 'en-US'
      translate.use(langCode)
    }

    this.languageEvent()

    this.subs = this.router.events
    .pipe(filter(e => e instanceof NavigationEnd))
    .subscribe((val: any) => {

      if (!val?.url.includes('home')) {
        this._eventService.showModalLanguage.next(false)
      }

      if (val?.url.includes('profile')) {
        this.setMapKey()
      }

      if (val?.url.includes('checkout')) {
        this.setStripeJs()
      }
    })
    this.storeInfo$.subscribe(result => {
      if (!result.storeSlug && !result.storeId) return
      this.storeInfo = result
    })
  }

  ngOnInit(): void {
    this.retrieveDataFromResolver();
  }

  ngAfterViewInit(): void {
    this.mergedEvent$Sub = this._eventService.mergedEvent.subscribe((data) => {
      if (data && data.isMerged) {
        this.qrInfo.isMerged = data.isMerged
        this.qrInfo.openBillData = data.openBillData
        this.isShowModalMerged = data.isMerged
        this.storeInfo.isMerged = data.isMerged
        this.storeInfo.openBillData = data.openBillData
      }
    })
  }

  retrieveDataFromResolver() {
    this._activatedRoute.data.subscribe({
      next: (response) => {
        const dataQRInfo = this._dataStorageService.getData('qrInfoResponse');
        if (dataQRInfo) {
          this.qrInfo = dataQRInfo;
        }

        const dataStoreInfo = this._dataStorageService.getData('storeInfoResponse');
        if (dataStoreInfo) {
          this.storeInfo = dataStoreInfo;
          this.isMerchantCustomLoadingSpinner = Object.keys(environment.LOADING_SPINNER_CUSTOM).includes(String(this.storeInfo?.merchantId))
          this.addPreload(this.getSpinnerAnimation.url || '')
        }

        if (isPlatformBrowser(this.platformId)) {
          const fragment = window.location.hash
          if (!fragment) {
            this._activatedRoute.queryParams.subscribe((queryParams: Params) => {
              if (queryParams['qr'] && this.storeInfo && !this.storeInfo.qr.includes(ConfigSetting.FROM_CLAIM_POINTS)) {
                this.getQrInfo(queryParams)
              }
              this.isDelivery = this._viewService.getServiceType(queryParams['qr']) === ServiceType.DELIVERY
            })
          }
        }

        this.routerSubscription = this.router.events.subscribe((event) => {
          if (event instanceof NavigationEnd && this.storeInfo && !this.storeInfo.qr.includes(ConfigSetting.FROM_CLAIM_POINTS)) {
            this.checkQRStorageId()
          }
        });

        this._spinner.hide();
      },
      error: (err: Error) => {
        console.log(err);
        this._spinner.hide()
      },
    });
  }

  ngDoCheck(): void {
    
    if (this.storeInfo?.shopHoliday) {
      this.isShowStoreHoliday = true
    }

    if (this.storeInfo?.shopClosed) {
      this.isShowStoreClosed = true
    }

    if (this.storeInfo?.shopHoliday && this.storeInfo?.shopClosed) {
      this.isShowStoreClosed = false
      this.isShowStoreHoliday = true
    }
    
    this.persistentDataLocalstorage()
  }

  checkQRStorageId() {
    if (!this._viewService.getServiceType(this.storeInfo.qr)) {
      this._activatedRoute.queryParams.subscribe(params => {
        if (this.storeInfo.serviceType && this.storeInfo.serviceType?.length > 1 && !this.router.url.includes('order-service') && !this.hasRedirected) {
          if (this._viewService.getServiceType(this.storeInfo.qr)) return // if service type exist in storage, then return;

          this.router.navigate([`/order-service`], {
            queryParams: params
          }).then(() => {
            this._spinner.show()
            window.location.reload()
          })

          this.hasRedirected = true
        } else if (this.storeInfo.serviceType && this.storeInfo.serviceType?.length === 1) {
          if (this.storeInfo.serviceType[0] === ServiceType.DELIVERY && params['qr'] && !this._viewService.getServiceType(this.storeInfo.qr)) {
            this.router.navigate([`order-service`], {
              queryParams: params
            })
          } else {
            this._viewService.setServiceType(this.storeInfo.serviceType[0], this.storeInfo.qr)
          }
        }
      })
    } 

    if (!this._viewService.getCustomerDeviceId(this.storeInfo.qr)) {
      this._viewService.setCustomerDeviceId(this.storeInfo.customerDeviceId as string, this.storeInfo.qr)
    } 
    
    if (!this._viewService.getDeviceId(this.storeInfo.qr)) {
      this._viewService.setDeviceId(this.storeInfo.sessionDeviceId as string, this.storeInfo.qr)
    }

    this.persistentDataLocalstorage()
  }

  persistentDataLocalstorage() {
    if (!this._viewService.get(localStorageKey.storeInfo) || Object.keys( this._viewService.get(localStorageKey.storeInfo) || {}).length === 0) {
      this._viewService.set(localStorageKey.storeInfo, JSON.stringify(this.storeInfo))
    }

    if (!this._viewService.get(localStorageKey.qrInfo) || Object.keys( this._viewService.get(localStorageKey.qrInfo) || {}).length === 0) {
      this._viewService.set(localStorageKey.qrInfo, JSON.stringify(this.qrInfo))
    }
  }

  getChild(activatedRoute: ActivatedRoute): ActivatedRoute {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild)
    } else {
      return activatedRoute
    }
  }

  getStoreInfo() {
    if (!this.storeInfo) return

    this.storeInfo.storeSlug = this.qrInfo.storeSlug
    this.storeInfo.isLoading = false

    if (this.storeInfo?.storeSlug) {
      const isCCDeviceIdExist = this._viewService.getCustomerDeviceId(this.storeInfo?.qr)
      const isSCDeviceIdExist = sessionStorage.getItem('customerDeviceId')
      
      if((!isCCDeviceIdExist && !isSCDeviceIdExist) || (!isCCDeviceIdExist && isSCDeviceIdExist)) {
        let customerDeviceId = uuidv4()

        if (this.qrInfo?.customerDeviceId && !this._viewService.getCustomerDeviceId(this.storeInfo?.qr)) { 
          customerDeviceId = this.qrInfo?.customerDeviceId
        }

        this._viewService.setCustomerDeviceId(customerDeviceId, this.storeInfo?.qr)
        sessionStorage.setItem('customerDeviceId', this._viewService.getCustomerDeviceId(this.storeInfo?.qr))
      } else if((isCCDeviceIdExist && !isSCDeviceIdExist) || (isCCDeviceIdExist && isSCDeviceIdExist)) {
        sessionStorage.setItem('customerDeviceId', this._viewService.getCustomerDeviceId(this.storeInfo?.qr))
      } 

      if(isCCDeviceIdExist !== isSCDeviceIdExist) {
        sessionStorage.removeItem('productCategory')
        sessionStorage.removeItem('categories')
        sessionStorage.removeItem('lastCatMenu')
        sessionStorage.removeItem('checkoutField')
      }
    }

    if (this.storeInfo.brandLogo) {
      const favIcon: HTMLLinkElement = this.document.querySelector(
        '#favicon'
      ) as HTMLLinkElement
      favIcon.href = this.storeInfo.brandLogo
    }

    if (this.qrInfo) {
      Object.assign(this.storeInfo, {
        isAlreadyOrder: this.qrInfo.isAlreadyOrder,
        isOpenBill: this.qrInfo.isOpenBill,
        openBillId: this.qrInfo.openBillId,
        paxSizeSelected: this.qrInfo.paxSizeSelected,
        paxSizeMax: this.qrInfo.paxSizeMax,
        paxSizeChangeable: this.qrInfo.paxSizeChangeable,
        isDineInQR: this.qrInfo.isDineInQR,
        sessionDeviceId: this.qrInfo.sessionDeviceId,
        invoice: this.qrInfo.invoice,
        tableIds: this.qrInfo.tableIds,
        qrType: this.qrInfo.qrType,
        tableNumber: this.qrInfo.tableNumber,
        tableSplitNumber: this.qrInfo.tableSplitNumber,
        configService: this.qrInfo.serviceType,
        serviceType: this.qrInfo.serviceType,
        customerDeviceId: this.qrInfo.customerDeviceId,
        isReadOnly: this.qrInfo.isReadOnly,
        qr: this.qrInfo?.qr,
        openBillData: this.qrInfo?.openBillData,
        isTableManagementActive: this.qrInfo?.isTableManagementActive,
        isQueue: this.qrInfo?.isQueue
      })
    }

    if(this.storeInfo?.operatingTiming) {
      this.storeInfo?.operatingTiming.forEach((operationItem) => {
        const dayIndex = new Date().getDay() || 7
        if(operationItem.dayIndex === dayIndex) {
          this.storeInfo.operatingTimingSelected = operationItem
        }
      })
    }

    if(this.storeInfo?.deliveryTiming) {
      this.storeInfo?.deliveryTiming.forEach((operationItem) => {
        const dayIndex = new Date().getDay() || 7
        if(operationItem.dayIndex === dayIndex) {
          this.storeInfo.deliveryTimingSelected = operationItem
          if (this.storeInfo.deliveryTimingSelected.operationHoursModelList.length) {
            const {timeStartAt, timeEndAt} = this.storeInfo.deliveryTimingSelected.operationHoursModelList[0]
            this.timeStart = timeStartAt
            this.timeEnd = timeEndAt
          }
        }
        this.availableDeliveryDays.push(operationItem.day)
      })
    }

    if (this.storeInfo.currency) {
      this.storeInfo.digitsInfo = this.storeInfo.roundingModel.pricingType !== 'CENT' ? '1.0-0' : ''
      this.storeInfo.locale = getCurrencyLocale(this.storeInfo.currency)
    }

    this.setStoreInfoUI(this.storeInfo)
    this._viewService.setStoreInfo(this.storeInfo)
    this._store.dispatch(countOfCartUpdate({ count: 0 }))

    this.logService.log({ 
      type: 'INFO',
      merchantId: this.storeInfo?.merchantId,
      storeId: this.storeInfo?.storeId,
      qr: this.storeInfo?.qr,
      qrType: this.storeInfo?.qrType ?? '',
      dateTime: `${new Date()}`,
      body: JSON.stringify({
        infoUser: {
          event: MicroSentryData.StoreInfo.message,
          storeName: this.storeInfo?.name,
          className: 'AppComponent',
          methodName: 'getStoreInfo',
          deviceId: this._viewService.getDeviceId(this.storeInfo?.qr),
          customerDeviceId: this._viewService.getCustomerDeviceId(this.storeInfo?.qr),
          serviceType: this._viewService.getServiceType(this.storeInfo?.qr),
          userAgent: navigator.userAgent,
        },
        data: this.storeInfo
      })
    })
  }

  getQrInfo(queryParams: Params) {
    if (!this.qrInfo) return

    this.storeSlug = this.qrInfo.storeSlug
    this.qrInfo.qr = queryParams?.['qr']

    if (this.qrInfo.isMerged && this.qrInfo.openBillData && this.qrInfo.qrType === 'DYNAMIC') {
      this.isShowModalMerged = true
    }

    if (this.qrInfo && this.qrInfo?.sessionDeviceId !== 'init') {
      this._viewService.setDeviceId(
        this.qrInfo?.sessionDeviceId ?? '',
        this.qrInfo.qr
      )
    }

    if (this.qrInfo.serviceType?.length === 0) { 
      this.isShowModalContactCashier = true
    }

    if (!this._viewService.getCustomerDeviceId(this.qrInfo?.qr)) {
      this._viewService.setCustomerDeviceId(this.qrInfo?.customerDeviceId as string, this.qrInfo?.qr)
    } 
    
    if (!this._viewService.getDeviceId(this.qrInfo?.qr)) {
      this._viewService.setDeviceId(this.qrInfo?.sessionDeviceId as string, this.qrInfo?.qr)
    }
    
    if (!this._viewService.getServiceType(this.qrInfo?.qr)) {
      this._activatedRoute.queryParams.subscribe(params => {
        if ((this.qrInfo?.serviceType || []).includes(ServiceType.CATALOG)) {
          this._viewService.setServiceType(ServiceType.CATALOG, this.qrInfo?.qr)
          if (environment.STORE_QR_WITH_CATALOG_SERVICE.includes(this.qrInfo?.qr || '')) {
            this.router.navigate(['home'], {
              queryParams: params
            })
          }
        }
        if (this.qrInfo?.serviceType && this.qrInfo?.serviceType?.length > 1 && !this.router.url.includes('order-service') && !this.hasRedirected) {
          if (this._viewService.getServiceType(this.qrInfo?.qr)) return // if service type exist in storage, then return;

          if (params['qr']) {
            this.router.navigate([`order-service`], {
              queryParams: params
            })
          } else {
            this.router.navigate([`${this.qrInfo?.storeSlug}/order-service`])
          }
          this.hasRedirected = true
        } else if (this.qrInfo?.serviceType && this.qrInfo?.serviceType?.length === 1) {
          if (this.qrInfo?.serviceType[0] === ServiceType.DELIVERY && params['qr'] && !this._viewService.getServiceType(this.qrInfo?.qr)) {
            this.router.navigate([`order-service`], {
              queryParams: params
            })
          } else {
            this._viewService.setServiceType(this.qrInfo?.serviceType[0], this.qrInfo?.qr)
          }
        }
      })
    }

    const isDinein =
      this.qrInfo &&
      this.qrInfo?.serviceType.length === 1 &&
      this.qrInfo?.serviceType[0] === 'DINE_IN'

    const isTakeout =
      this.qrInfo &&
      this.qrInfo?.serviceType.length === 1 &&
      this.qrInfo?.serviceType[0] === 'TAKEOUT'

    if (isDinein) {
      this._viewService.setServiceType('DINE_IN', this.qrInfo?.qr)
    }

    if (isTakeout) {
      this._viewService.setServiceType('TAKEOUT', this.qrInfo?.qr)
    }

     /** 
     * Queue Id Set On local Storage
     */
    if (!this._viewService.getQueueId(this.qrInfo?.qr)) {
      this._viewService.setQueueId(queryParams?.['queueId'], this.qrInfo?.qr)

      if (this._viewService.getQueueId(this.qrInfo?.qr)) {
        this.removeQueryParamsWithoutReload(['queueId'])
      }
    } else {
      this.removeQueryParamsWithoutReload(['queueId'])
    }


    /** 
     * Place order Queue Set On local Storage
     */
    if (!this._viewService.getPlaceOrderQueue(this.qrInfo?.qr)) {
      this._viewService.setPlaceOrderQueue(queryParams?.['placeOrder'], this.qrInfo?.qr)

      if (this._viewService.getPlaceOrderQueue(this.qrInfo?.qr)) {
        this.removeQueryParamsWithoutReload(['placeOrder'])
      }
    } else {
      this.removeQueryParamsWithoutReload(['placeOrder'])
    }

    /** 
     * Already Order Queue Set On local Storage
     */
    if (!this._viewService.getAlreadyOrder(this.qrInfo?.qr)) {
      this._viewService.setAlreadyOrder(queryParams?.['alreadyOrder'], this.qrInfo?.qr)

      if (this._viewService.getAlreadyOrder(this.qrInfo?.qr)) {
        this.removeQueryParamsWithoutReload(['alreadyOrder'])
      }
    } else {
      this.removeQueryParamsWithoutReload(['alreadyOrder'])
    }

    this._viewService.setQRInfo(this.qrInfo)

    this.getStoreInfo()
  }

  removeQueryParamsWithoutReload(paramsToRemove: string[]) {
    const url = new URL(window.location.href);
  
    // Remove specific query parameters
    paramsToRemove.forEach(param => url.searchParams.delete(param));
  
    // Update the URL without reloading the page
    window.history.pushState({ path: url.href }, '', url.href);
  }
  


  setStoreInfoUI(settings: any) {
    const storeFontStyle = settings?.fontStyle
      ? settings?.fontStyle
      : 'Poppins, sans-serif'

    const storePrimaryColor = settings?.colorPrimary
      ? settings?.colorPrimary
      : '#00489a'
    const storeSecondaryColor = settings?.colorSecondary
      ? settings?.colorSecondary
      : '#c9e7ff'
    const storeTextPrimary = settings?.colorTextButtonPrimary
      ? settings?.colorTextButtonPrimary
      : '#ffffff'
    const storeTextSecondary = settings?.colorTextButtonSecondary
      ? settings?.colorTextButtonSecondary
      : '#00489a'
    const storeTextColor = settings?.colorFont ? settings?.colorFont : '#404040'

    this.renderer.setStyle(
      this.document.documentElement,
      '--font-family',
      storeFontStyle,
      2
    )

    this.renderer.setStyle(
      this.document.documentElement,
      '--store-primary-color',
      storePrimaryColor,
      2
    )
    this.renderer.setStyle(
      this.document.documentElement,
      '--store-secondary-color',
      storeSecondaryColor,
      2
    )
    this.renderer.setStyle(
      this.document.documentElement,
      '--store-text-primary-btn',
      storeTextPrimary,
      2
    )
    this.renderer.setStyle(
      this.document.documentElement,
      '--store-text-secondary-btn',
      storeTextSecondary,
      2
    )
    this.renderer.setStyle(
      this.document.documentElement,
      '--store-text-color',
      storeTextColor,
      2
    )
  }

  languageEvent() {
    this._eventService.showModalLanguage.subscribe(result => {
      this.isShowLanguage = result
    })
  }

  setMapKey() {
    addScript(
      this.renderer,
      null,
      `https://maps.googleapis.com/maps/api/js?key=${environment.MAP_KEY}&libraries=places`,
      true,
      this.document
    )
  }

  setStripeJs(): void {
    addScript(
      this.renderer,
      null,
      'https://js.stripe.com/v3/',
      true,
      this.document
    )
  }

  goToInvoice() {
    if (this.qrInfo?.qrType === ConfigSetting.STATIC && this.qrInfo?.isMerged) {
      window.location.href = `/order/${this.qrInfo?.openBillData?.invoice}?qr=${this.qrInfo?.openBillData?.qrHash}&customerDeviceId=${this.qrInfo?.openBillData?.customerDeviceId}`
    } else if (this.qrInfo?.qrType === ConfigSetting.DYNAMIC && this.qrInfo?.isMerged) {
      this._spinner.show()
      this._domainService.getQRInfo(this.qrInfo?.qr as string).subscribe(
        {
          next: (response) => {
            let data = response.data
            if (data?.hasOwnProperty('openBillData')) {
              window.location.href = `/order/${data?.openBillData?.invoice}?qr=${data?.openBillData?.qrHash}&customerDeviceId=${data?.openBillData?.customerDeviceId}`
            }
          },
          error: (error) => {
            this._spinner.hide()
          }
        }
      )
    }
  }

  get getSpinnerAnimation(): Partial<ILoadingSpinnerList> {
    let result: Partial<ILoadingSpinnerList> = {
      url: LoadingSpinnerList.Default.url,
      width: LoadingSpinnerList.Default.width,
      overlay: LoadingSpinnerList.Default.overlay
    }

    if (this.isMerchantCustomLoadingSpinner) {
      result.url = LoadingSpinnerList.Ichiho.url
      result.width = LoadingSpinnerList.Ichiho.width
      result.overlay = LoadingSpinnerList.Ichiho.overlay
    }

    return result
  }

  addPreload(imagePath: string): void {
    const linkElement = this.renderer.createElement('link')
    this.renderer.setAttribute(linkElement, 'rel', 'preload')
    this.renderer.setAttribute(linkElement, 'href', imagePath)
    this.renderer.setAttribute(linkElement, 'as', 'image')
    this.renderer.appendChild(document.head, linkElement)
  }

  ngOnDestroy(): void {
    this.subs?.unsubscribe()
    this._eventService.showModalLanguage?.unsubscribe()
    this.mergedEvent$Sub?.unsubscribe()

    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }
}
