export class HttpUtils {
  static replaceUrlVariables(url: string, value: any): string {
    let parsedUrl = url

    for(const param in value) {
      parsedUrl = parsedUrl.replace(`:${param}`, value[param])
    }
    
    return parsedUrl
  }
}