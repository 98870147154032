import { inject, Injectable } from "@angular/core"
import { Store } from "@ngrx/store"
import { selectStoreInfo } from "@state-management/store/store-info.selector"
import { Observable, take } from "rxjs"
import { CartService } from "./cart.service"
import { CartCount, CartDelete, CartList } from "@models/cart/cart.model"
import { Response } from '@models/response'

@Injectable({
  providedIn: 'root'
})
export class CartFacadeService {
  private store = inject(Store)
  private cartServive = inject(CartService)

  constructor() {
    this.store.select(selectStoreInfo).subscribe(storeInfo => {
      this.cartServive.setMenuVersion(storeInfo.menuVersion)
    })
  }

  getCarts(storeSlug: string, qr: string): Observable<Response<CartList[]>> {
    return this.cartServive.getCarts(storeSlug, qr)
  }

  addCart(storeSlug: string, payload: CartList): Observable<Response<CartList>> {
    return this.cartServive.addCart(storeSlug, payload)
  }

  updateCart(storeSlug: string, id: number, payload: CartList, qrHash: string): Observable<Response<CartList>> {
    return this.cartServive.updateCart(storeSlug, id, payload, qrHash)
  }

  deleteCart(storeSlug: string, id: number, payload: CartDelete, qrHash: string): Observable<Response<CartDelete>> {
    return this.cartServive.deleteCart(storeSlug, id, payload, qrHash)
  }

  checkCart(storeSlug: string, payload: CartDelete): Observable<Response<CartDelete>> {
    return this.cartServive.checkCart(storeSlug, payload)
  }

  getCartCount(storeSlug: string, qr: string = ''): Observable<Response<CartCount>> {
    return this.cartServive.getCartCount(storeSlug, qr)
  }
}