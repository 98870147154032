import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { AuthMemberService } from '@auth/data-access/auth-member.service'
import { ViewService } from '@data-access/view/view.service'
import { StoreInfo } from '@models/home.model'
import { Store } from '@ngrx/store'
import { CartActions } from '@state-management/cart/cart.actions'
import { ErrorMessage } from '@utils/const'
import { Observable, throwError } from 'rxjs'
import { tap } from 'rxjs/operators'
import { ToastService } from '../data-access/toast/toast.service'
import { DataStorageService } from '../data-storage/data-storage.service'
import { HttpError } from '../models/common'

@Injectable()
export class ErrorResponseInterceptor implements HttpInterceptor {
  storeInfo?: StoreInfo
  constructor(
    private toastService: ToastService,
    private router: Router,
    private _store: Store,
    private _viewService: ViewService,
    private _authMemberService: AuthMemberService,
    private _dataStorageService: DataStorageService,
  ) {
    const dataStoreInfo = this._dataStorageService.getData('storeInfoResponse');
    this.storeInfo = dataStoreInfo
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(
        (res: any) => {
          if (res.hasOwnProperty('body') && res?.body.hasOwnProperty('code')) {
            if (res?.body.code === 'FORBIDDEN') {
              this._authMemberService.logout().subscribe(
                {
                  next: (result) => {
                    this._viewService.logout('login')
                    this.clearCarts()
                  },
                  error: () => {

                  }
                }
              )
            }
          }
        },
        (error: any) => {
          switch (error?.status) {
            case HttpError.BadRequest:
              if (error.error.error === ErrorMessage.MERCHANT_TABLE_QR_OPEN_BILL_PAID) {
                console.log(error.error.error)
                return
              }
              break
            case HttpError.NotFound:
              if (error.error.error === ErrorMessage.MERCHANT_NOT_FOUND) {
                this.router.navigate(['/e/404'])
                this.toastService.broadcast('SHOW_TOAST', error?.error?.error)
              } else if (
                error.error.error === ErrorMessage.MERCHANT_TABLE_MANAGEMENT_NEED_ACTIVATE
              ) {
                this.router.navigate([
                  '/e/table-management-requires-activation'
                ])
              }
              break

            case HttpError.UnprocessableEntityError:
              if (error.error.error === ErrorMessage.ORDER_CART_EMPTY) {
                return
              }

              if (error.error.hasOwnProperty('data') && Object.keys(error.error.data).length > 0) {
                if (error.error?.data['error-message'] === ErrorMessage.ORDER_HAS_BEEN_ALREADY_VOIDED) {
                  this.router.navigate(['/e/please-scan-qr/order-has-been-voided'])
                } else if (error.error?.data['error-message'] === ErrorMessage.ORDER_HAS_BEEN_PAID) {
                  this.router.navigate(['/e/please-scan-qr/order-has-been-paid'])
                }
              } 
              
              if (!error.error.hasOwnProperty('data') && error.error.error === ErrorMessage.MERCHANT_TABLE_QR_SESSION_DEVICE_ID_NOT_VALID) {
                this.router.navigate(['/e/please-scan-new-qr'])
              }
              break

            case HttpError?.Forbidden:
              if (error?.error?.error === ErrorMessage.CUSTOMER_LOGIN_REQUIRED) {
                this._viewService.logout('login', 'YOUR_SESSION_EXPIRED')
                this.clearCarts()
              }
              break

            case HttpError?.InternalServerError:
              break

            case HttpError?.Unauthorized:
              console.error(error?.error?.error || 'Error unauthorized')
              break

            case HttpError?.ServerDown:
              break
            default:
              this.toastService.broadcast('SHOW_TOAST', error?.error?.error)
              return true
          }
          return throwError(() => new Error(error?.error?.error))
        }
      )
    )
  }

  private clearCarts(): void {
    this._store.dispatch(CartActions.clearCarts())
  }
}
