import { ClaimPoints } from 'src/app/claim-points/interface/claim-points.interface'
import { QRInfo } from './domain.model'
import { TimeZone } from './utility.model'

export interface StoreInfo extends QRInfo {
  alreadyOrder?: boolean
  storeSlug: string
  brandLogo: string
  bannerDesktop: string[]
  bannerMobile: string[]
  name: string
  announcementEnable: boolean
  announcementMessage: string
  template: string
  contacts: Contact[]
  configService: string[]
  fontStyle: string
  colorPrimary: string
  colorSecondary: string
  colorFont: string
  colorTextButtonPrimary: string
  colorTextButtonSecondary: string
  shopClosed: boolean
  shopOperationalOpenAt: number
  shopOperationalClosedAt: number
  shopHoliday: boolean
  shopHolidayOpenAt: number
  shopHolidayClosedAt: number
  isLoggedIn?: boolean
  currency: string
  country: string
  roundingModel: {
    roundingMethod: string
    roundingNearest: string
    pricingType: string
  }
  timeZone: string
  timeZoneDetail?: TimeZone
  operatingTiming: OperationTiming[]
  operatingTimingSelected: OperationTiming
  isLoading: boolean
  merchantId: number
  storeId: number
  qr: string
  isMerged: boolean
  digitsInfo: string
  isUseLoyalty: boolean
  orderSessionHash: string
  locale: string,
  code: string,
  unclaimedPointsData: ClaimPoints
  isDeliveryNotAvailable?: boolean
  isQueue: boolean
  tipping: TippingType
  deliveryTiming: OperationTiming[]
  deliveryTimingSelected?: OperationTiming
  scheduledOrder: {
    isEnabled: boolean
    leadTime: {
      days: number
      hours: number
    }
    serviceModeConfig: ServiceModeConfig[]
    maxDeliveryDays: number
  }
  recommendationConfig?: RecommendationConfig
  menuVersion: MenuVersion
}

export enum MenuVersion {
  V1 = 'V1',
  V2 = 'V2'
}

interface RecommendationConfig {
  recommendation: Recommendation
  upselling: Upselling
}

interface Recommendation {
  enable: boolean
  title: string
}

export interface Upselling {
  enable: boolean
  title: string
}


export interface OperationTiming {
  day: string
  dayIndex: number
  operationHoursModelList: OperationHour[]
  twentyFourHour: boolean
}

export interface OperationHour {
  timeStartAt: string
  timeEndAt: string
}

export interface Contact {
  status: number
  createdBy: string
  virtualId: string
  identity: string
  type: string
  url: string
}

export interface IHeaderMenu {
  barIcon: boolean,
  searchIcon: boolean,
  cartIcon: boolean
}

export interface ISidebarMenu {
  home: boolean,
  order: boolean,
  favorite: boolean,
  myCoupon: boolean,
  myvoucher: boolean,
  orderService: boolean,
  myProfile: boolean,
  claimPoints: boolean,
  login: boolean,
  logout: boolean
}

export interface TippingType {
  enable: boolean,
  serviceType: string[],
  products: string[],
  type: string,
  tippingValue: number[]
}

export interface ServiceModeConfig {
  code: string
  isEnabled: boolean
}