import { CustomMenuV2 } from '@models/custom-menu-v2.model'
import { createReducer, on } from '@ngrx/store'
import { customMenuV2Update } from './custom-menu-select.actions'

export const initialState: CustomMenuV2 | null = Object.create(null)

export const customMenuSelectV2Reducer = createReducer(
  initialState,
  on(customMenuV2Update, (state, customMenu) => ({
    ...state,
    ...customMenu
  }))
)
