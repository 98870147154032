import { CartListV1 } from "./cart-v1.model";
import { CartListAdapterStrategy } from "./cart.adapter";
import { CartList } from "./cart.model";

export class CartListV1Adapter implements CartListAdapterStrategy {
  adapt(cartList: CartListV1): CartList {
    return {
      ...cartList,
      cmProductId: undefined
    }
  }
}