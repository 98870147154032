import { inject, Injectable } from '@angular/core';
import { ViewService } from '@data-access/view/view.service';
import { CartFacadeService } from '@home/data-access/cart/cart-facade.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { countOfCartUpdate } from '@state-management/count-of-cart/count-of-cart.actions';
import { selectStoreInfo } from '@state-management/store/store-info.selector';
import { combineLatest, of } from 'rxjs';
import { concatMap, map, withLatestFrom } from 'rxjs/operators';
import { LogService } from '../../log/log.service';
import { CartActions } from './cart.actions';

@Injectable()
export class CartEffects {
  private _store = inject(Store);
  private _actions$ = inject(Actions);
  private _viewService = inject(ViewService);
  private _cartFacadeService = inject(CartFacadeService);
  private _logService = inject(LogService);

  clearCarts$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(CartActions.clearCarts),
      withLatestFrom(this._store.select(selectStoreInfo)),
      concatMap(([action, storeInfo]) => {
        const payload = {
          deviceId: this._viewService.getDeviceId(storeInfo.qr),
          customerDeviceId: this._viewService.getCustomerDeviceId(storeInfo.qr),
          serviceType: this._viewService.getServiceType(storeInfo.qr)
        }

        return this._cartFacadeService.checkCart(storeInfo.storeSlug, payload)
      }),
      map(() => countOfCartUpdate({ count: 0 }))
    )
  });

  queryCount$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(CartActions.queryCount, CartActions.queryCountWithLog),
      withLatestFrom(this._store.select(selectStoreInfo)),
      concatMap(([action, storeInfo]) => {
        return combineLatest([this._cartFacadeService.getCartCount(storeInfo.storeSlug, storeInfo.qr), of(storeInfo), of(action)])
      }),
      map(([response, storeInfo, action]) => {

        const logInfo = 'logInfo' in action ? action.logInfo : null;

        if (logInfo) {
          this._logService.log({
            type: 'INFO',
            merchantId: storeInfo?.merchantId as number,
            storeId: storeInfo?.storeId as number,
            qr: storeInfo?.qr as string,
            qrType: storeInfo?.qrType ?? '',
            dateTime: `${new Date()}`,
            body: JSON.stringify({
              infoUser: {
                event: 'Cart Counter',
                storeName: storeInfo?.name,
                className: logInfo.className,
                methodName: logInfo.methodName,
                deviceId: this._viewService.getDeviceId(storeInfo?.qr),
                customerDeviceId: this._viewService.getCustomerDeviceId(storeInfo?.qr),
                serviceType: this._viewService.getServiceType(storeInfo?.qr),
                userAgent: navigator.userAgent,
              },
              data: response.data
            })
          })
        }

        return countOfCartUpdate({ count: response.data.count })
      })
    )
  });
}