import { adaptModifier } from "@models/product-v2.model";
import { CartListV2 } from "./cart-v2.model";
import { CartListAdapterStrategy } from "./cart.adapter";
import { CartList } from "./cart.model";

export class CartListV2Adapter implements CartListAdapterStrategy {
  adapt(cartList: CartListV2): CartList {
    return {
      ...cartList,
      actionDetail: {
        modifiers: (cartList.actionDetail?.modifiers || []).map(adaptModifier)
      },
      cmProductId: cartList.cmProductId
    }
  }
}