import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'
import { Inject, Injectable, PLATFORM_ID } from '@angular/core'
import { ViewService } from '@data-access/view/view.service'
import { CartListV1Adapter } from '@models/cart/cart-v1.adapter'
import { CartListV1 } from '@models/cart/cart-v1.model'
import { CartListV2Adapter } from '@models/cart/cart-v2.adapter'
import { CartListV2 } from '@models/cart/cart-v2.model'
import { CartListAdapterStrategy } from '@models/cart/cart.adapter'
import { CartCount, CartDelete, CartList } from '@models/cart/cart.model'
import { MenuVersion } from '@models/home.model'
import { Response } from '@models/response'
import { HttpUtils } from '@utils/http'
import { map, Observable, tap } from 'rxjs'

class CartListAdapterFactory {
  static resolveCartListAdapter(menuVersion: MenuVersion): CartListAdapterStrategy {
    switch (menuVersion) {
      case MenuVersion.V1:
        return new CartListV1Adapter()
      case MenuVersion.V2:
        return new CartListV2Adapter()
      default:
        throw new Error('Invalid menu version')
    }
  }
}

const CartEndpoint = {
  getList: {
    [MenuVersion.V1]: '/api/idefood/:storeSlug/cart',
    [MenuVersion.V2]: '/api/idefood/:storeSlug/cart/v2' // TODO: make sure the endpoint is correct with Alex
  },
  add: {
    [MenuVersion.V1]: '/api/idefood/:storeSlug/cart',
    [MenuVersion.V2]: '/api/idefood/:storeSlug/cart/v2'
  },
  update: {
    [MenuVersion.V1]: '/api/idefood/:storeSlug/cart/:id',
    [MenuVersion.V2]: '/api/idefood/:storeSlug/cart/v2/:id'
  },
  delete: {
    [MenuVersion.V1]: '/api/idefood/:storeSlug/cart/:id',
    [MenuVersion.V2]: '/api/idefood/:storeSlug/cart/v2/:id'
  },
  check: {
    [MenuVersion.V1]: '/api/idefood/:storeSlug/cart',
    [MenuVersion.V2]: '/api/idefood/:storeSlug/cart/v2'
  }
}

@Injectable({
  providedIn: 'root'
})
export class CartService {
  private menuVersion: MenuVersion = MenuVersion.V1

  constructor(
    private http: HttpClient,
    @Inject(PLATFORM_ID) public readonly platformId: string,
    private _viewService: ViewService
  ) { }

  setMenuVersion(menuVersion: MenuVersion) {
    this.menuVersion = menuVersion
  }

  getCarts(storeSlug: string, qr: string): Observable<Response<CartList[]>> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
    let params = new HttpParams()
      .set('deviceId', this._viewService.getDeviceId(qr))
      .set('serviceType', this._viewService.getServiceType(qr))
      .set('customerDeviceId', this._viewService.getCustomerDeviceId(qr))

    const adapter = CartListAdapterFactory.resolveCartListAdapter(this.menuVersion)
    const url = HttpUtils.replaceUrlVariables(CartEndpoint.getList[this.menuVersion], { storeSlug })

    return this.http.get<Response<(CartListV1 | CartListV2)[]>>(
      HttpUtils.replaceUrlVariables(url, { storeSlug }),
      {
        headers: headers,
        params: params
      }
    ).pipe(
      map(res => ({
        ...res,
        data: res.data.map(cart => adapter.adapt(cart))
      }))
    )
  }

  addCart(
    storeSlug: string,
    payload: CartList
  ): Observable<Response<CartList>> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json')

    const adapter = CartListAdapterFactory.resolveCartListAdapter(this.menuVersion)
    const url = HttpUtils.replaceUrlVariables(CartEndpoint.add[this.menuVersion], { storeSlug })

    return this.http.post<Response<CartListV1 | CartListV2>>(
      url,
      payload,
      {
        headers: headers
      }
    ).pipe(
      map(res => ({
        ...res,
        data: adapter.adapt(res.data)
      })),
      tap(() => {
        this._viewService.setIsCartUpdated('true', payload.qrHash)
      })
    )
  }

  updateCart(
    storeSlug: string,
    id: number,
    payload: CartList,
    qrHash: string
  ): Observable<Response<CartList>> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json')

    const adapter = CartListAdapterFactory.resolveCartListAdapter(this.menuVersion)
    const url = HttpUtils.replaceUrlVariables(CartEndpoint.update[this.menuVersion], { storeSlug, id })

    return this.http.put<Response<CartListV1 | CartListV2>>(
      url,
      payload,
      {
        headers: headers
      }
    ).pipe(
      map(res => ({
        ...res,
        data: adapter.adapt(res.data)
      })),
      tap(() => {
        this._viewService.setIsCartUpdated('true', qrHash)
      })
    )
  }

  deleteCart(
    storeSlug: string,
    id: number,
    payload: CartDelete,
    qrHash: string
  ): Observable<Response<CartDelete>> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json')

    const url = HttpUtils.replaceUrlVariables(CartEndpoint.delete[this.menuVersion], { storeSlug, id })

    return this.http.delete<Response<CartDelete>>(
      url,
      {
        headers: headers,
        body: payload
      }
    ).pipe(
      tap(() => {
        this._viewService.setIsCartUpdated('true', qrHash)
      })
    )
  }

  checkCart(
    storeSlug: string,
    payload: CartDelete
  ): Observable<Response<CartDelete>> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json')

    const url = HttpUtils.replaceUrlVariables(CartEndpoint.check[this.menuVersion], { storeSlug })

    return this.http.delete<Response<CartDelete>>(
      url,
      {
        headers: headers,
        body: payload
      }
    )
  }


  getCartCount(storeSlug: string, qr: string = ''): Observable<Response<CartCount>> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
    let params = new HttpParams()
      .set('deviceId', this._viewService.getDeviceId(qr))
      .set('serviceType', this._viewService.getServiceType(qr))
      .set('customerDeviceId', this._viewService.getCustomerDeviceId(qr))

    return this.http.get<Response<CartCount>>(
      `/api/idefood/${storeSlug}/cart/count`,
      {
        headers: headers,
        params: params
      }
    )
  }
}
